import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    a: {
      boxShadow: `none`,
      color: "rgb(224,62,62)",
    },
    "h1,h2,h3,h4,h5,h6": {
      marginTop: "1.5rem",
      color: "rgb(224,62,62)",
    },
    h3: {
      fontSize: "18px",
      fontWeight: "500",
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
