import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"

const Topbar = ({ title }) => {
  return (
    <header
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: "999",
        borderBottom: "1px solid #eee",
        background: "#fff",
      }}
    >
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `10px ${rhythm(3 / 4)}`,
        }}
      >
        <Link style={{ boxShadow: `none` }} to="/">
          {title}
        </Link>

        <Link style={{ boxShadow: `none`, marginLeft: "20px" }} to="/books">
          我的书单
        </Link>

        <a
          style={{ boxShadow: `none`, marginLeft: "20px" }}
          href="https://www.github.com/superman66"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
      </div>
    </header>
  )
}

export default Topbar
